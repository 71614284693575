<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col>
        <h1>
          Freshchat
        </h1>
        <b-form-checkbox
          v-model="freshchat.active"
          name="active"
          class="content-switch"
          switch
          ><b>{{$t('active')}}</b></b-form-checkbox
        >
      </b-col>
      <b-col cols="3" align-self="center">
        <b-alert
          align-v="center"
          variant="warning"
          :show="saveMessage !== ''"
          >{{ this.saveMessage }}</b-alert
        >
      </b-col>
      <b-col cols="auto">
        <!-- Button -->
        <b-button :disabled="saveDisabled" variant="primary" @click="doSave">
          {{$t('save')}}
        </b-button>
      </b-col>
    </b-row>
    <b-form-group :label="$t('name')">
      <b-form-input v-model="freshchat.appName"></b-form-input>
    </b-form-group>
    <b-form-group :label="$t('host')">
      <b-form-input v-model="freshchat.host"></b-form-input>
    </b-form-group>
    <b-form-group :label="$t('token')">
      <b-form-input v-model="freshchat.appToken"></b-form-input>
    </b-form-group>
    <b-row>
      <!-- LOGO  -->
      <b-col>
        <b-form-group label-size="lg" :label="$t('logo')">
          <ImageUpload :url="freshchat.appLogo" ref="image" :title="$t('logo')" @updateImage="updateLogo"/>
        </b-form-group>
      </b-col>

      <!-- BACKGROUND IMAGE  -->
      <b-col>
        <b-form-group label-size="lg" :label="$t('backgroundImage')">
          <ImageUpload :url="freshchat.backgroundImage" ref="image" :title="$t('backgroundImage')" @updateImage="updateBackground"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-group
      id="fieldset-1"
      :description="$t('chooseBackground')"
      :label="$t('backgroundColor')"
      label-for="input-1"
      class="admin-label"
    >
      <b-form-input
        id="input-1"
        v-model="freshchat.backgroundColor"
        type="color"
        trim
      ></b-form-input>
    </b-form-group>
    <b-form-group
      id="fieldset-1"
      :description="$t('chooseForeground')"
      :label="$t('foregroundColor')"
      label-for="input-1"
      class="admin-label"
    >
      <b-form-input
        id="input-1"
        v-model="freshchat.foregroundColor"
        type="color"
        trim
      ></b-form-input>
    </b-form-group>
  </b-overlay>
</template>
<script>
import ImageUpload from "@/components/admin/ImageUpload.vue" 

export default {
  components: {
    ImageUpload
  },
  data() {
    return {
      isLoading: false,
      saveDisabled: true,
      saveMessage: "",
    };
  },
  async created() {
    await this.$store.dispatch("Freshchat/fetchAll");
    this.saveDisabled = true;
    this.saveMessage = "";
  },
  watch: {
    freshchat: {
      handler() {
        this.saveMessage = "You have unsaved changes!";
        this.saveDisabled = false;
      },
      deep: true,
    },
  },
  computed: {
    freshchat() {
      return this.$store.state.Freshchat.all;
    },
  },
  methods: {
    async doSave() {
      this.isLoading = true;
      this.$refs.image.uploadImage();
      await this.$store.dispatch("Freshchat/update", this.freshchat);
      await this.$store.dispatch("Freshchat/fetchAll");
      this.$root.$bvToast.toast("Freshchat settings saved!", {
        variant: "success",
      });
      this.saveDisabled = true;
      this.saveMessage = "";
      this.isLoading = false;
    },
    updateLogo(newUrl) {
      this.freshchat.appLogo = newUrl
    },
    updateBackground(newUrl) {
      this.freshchat.backgroundImage = newUrl
    },
  },
};
</script>
<style lang="scss"></style>
